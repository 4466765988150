import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Action from '../components/Resource/Action';
import Section from '../components/Section';
import '../styles/pages/_spm.scss';
import '../styles/styles.scss';

const SelfPacedModule = () => (
  <Layout title="Self-Paced Module">
    <Section className="about-mission">
      <Container>
        <h1 className="h1">Self-Paced Module</h1>
        <Image
          filename="SPMlanding-image-hero@2x.jpg"
          className="about__hero-image"
        />
        <h2 className="h2 mt-1">
          Investigate Health Equity Challenges from Local to Global
        </h2>
        <p>
          Uncover the key factors necessary for achieving global health equity
          and explore the ways that the external world impacts individual health
          and wellbeing. In this interactive self-paced module, students will
          learn about the social determinants of health and consider ways of
          addressing health inequities in their own communities.
        </p>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row className="mb-0">
          <Column size={12} className="spm__column-name">
            Health Inequity
          </Column>
        </Row>
        <Row className="spm__row-image-container">
          <Column size={6} className="pl-0 pr-0">
            <Image filename="SPMlanding-image-getstarted@2x.jpg" />
          </Column>
          <Column size={6} className="spm__row-text-container">
            <p className="mb-0">SELF-PACED MODULE</p>
            <h2 className="spm__header-text">
              Impact of Health Inequity in Our Communities and World
            </h2>
            <p className="spm__desc-text">
              Does healthy living look the same for everyone? Unravel the
              complex intersection of factors that determine health and wellness
              with this self-paced module. This versatile learning tool can be
              completed in 15–20 minutes by students or broken up into multiple
              learning sessions.{' '}
            </p>
            <p className="spm__desc-text">
              After completing the module, students will receive a Certificate
              of Completion in recognition of their learning.
            </p>
            <div className="mb-1">
              <Button
                to="/files/modules/health-inequity/"
                isExternal
                target="_blank"
                style={{ fontSize: '1rem' }}
                data-click-type="Module Launch"
                data-click-name="Health Inequity"
              >
                <Icon name="externallink" marginRight />
                Get Started
              </Button>
            </div>
            <div>
              {/* <Button
                to="/pdfs/Takeda-SPM-ImpactofHealthInequity-V0.2.pdf"
                target="_blank"
                inverse
                style={{ fontSize: '1rem' }}
              >
                <Icon name="externallink" marginRight />
                Educator Guide
              </Button> */}
              <Action
                isButton={true}
                label="SELECT LANGUAGE"
                type="dropdown"
                actions={[
                  {
                    label: 'English',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0.2.pdf',
                    type: 'download',
                  },
                  {
                    label: 'French',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_FR.pdf',
                    type: 'download',
                  },
                  {
                    label: 'German',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_DE.pdf',
                    type: 'download',
                  },
                  {
                    label: 'Hindi',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_HI.pdf',
                    type: 'download',
                  },
                  {
                    label: 'Italian',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_IT.pdf',
                    type: 'download',
                  },
                  {
                    label: 'Japanese',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_JP.pdf',
                    type: 'download',
                  },
                  {
                    label: 'Portuguese',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_PT.pdf',
                    type: 'download',
                  },
                  {
                    label: 'Russian',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_RU.pdf',
                    type: 'download',
                  },
                  {
                    label: 'Simplified Chinese',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_ZH.pdf',
                    type: 'download',
                  },
                  {
                    label: 'Spanish',
                    path: '/pdfs/Takeda-SPM-ImpactofHealthInequity-V0_ES.pdf',
                    type: 'download',
                  },
                ]}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default SelfPacedModule;
